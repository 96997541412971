/*@import url('https://cdn.syncfusion.com/ej2/material.css');*/

.sidebar {
  box-shadow: rgb(113 122 131 / 11%) 0px 7px 30px 0px;
}
.nav-item,
.navbar {
  z-index: 10000;
}

.custom-tooltip {
  font-size: 0.75rem !important;
  z-index: 10;
}



@media  screen and (max-width:800px) {
  .sidebar{
    z-index: 10000000;
  }
}

.login {
  z-index: 99999;
}

.e-dlg-center-center, .e-quick-popup-wrapper.e-device{
  z-index: 1000000 !important;
}

::-webkit-scrollbar-thumb {
  background-color: rgb(216, 216, 216);
  border-radius: 40px;
}
::-webkit-scrollbar-track {
  background-color: transparent;
}

.custom-select::before,
    .custom-select::after {
        --size: 0.3rem;
        position: absolute;
        content: "";
        right: 1rem;
        pointer-events: none;
    }
    
    .custom-select::before {
        border-left: var(--size) solid transparent;
        border-right: var(--size) solid transparent;
        border-bottom: var(--size) solid black;
        top: 40%;
    }
      
    .custom-select::after {
        border-left: var(--size) solid transparent;
        border-right: var(--size) solid transparent;
        border-top: var(--size) solid black;
        top: 55%;
    }