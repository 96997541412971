table {
    font-family: Arial, Helvetica, sans-serif;
    width: 100%;
    border-collapse: separate;
    border-spacing: 0;
  }

  table thead {
    position: sticky;
    top: 0;
  }
  
  table th {
    border-bottom: 1px solid black;
    padding: 10px;
    text-align: left;
  }
  
  table td {
    border-bottom: 1px solid #ddd;
    padding: 10px;
    text-align: left;
  }
  
  table tr:nth-child(even){background-color: white;}
  table tr:nth-child(odd){background-color: white;}
  
  table tr:hover {background-color: #f4f8ed;}
  
  table th {
    padding-top: 6px;
    padding-bottom: 6px;
    text-align: left;
    background-color: white;
    color: black;

}

.selected-row {  
  background-color: #ddd; /*Highlight selected row*/
}