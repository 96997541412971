@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;500;600;700&display=swap');

body{
    margin: 0;
    padding:0;
    font-family: "Open Sans", sans-serif;
}

@tailwind base;

@layer base {
    input[type="number"]::-webkit-inner-spin-button,
    input[type="number"]::-webkit-outer-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
  }

@layer base {
    h1 {
        @apply font-serif text-3xl lg:text-3xl font-semibold text-dark-blue-600;
    }

    h3 {
        @apply font-serif text-2xl sm:text-3xl md:text-4xl xl:text-5xl text-dark-blue-600 font-bold leading-normal;
    }
    
    p {
        @apply text-base text-dark-green-950 font-normal text-left leading-normal;
    }
    
    h6 {
        @apply text-base uppercase font-semibold tracking-wide mb-2;
    }
}

@layer base {
    select {
        appearance: none;
        /* safari */
        -webkit-appearance: none;
        /* other styles for aesthetics */
        width: 100%;
        font-size: 1.15rem;
        padding: 0.675em 6em 0.675em 1em;
        background-color: #fff;
        /* border: 1px solid #caced1; */
        border-radius: 0.25rem;
        color: #000;
        cursor: pointer;
    }

    button {
        @apply transition-colors duration-200;
    }

    input, select {
        @apply text-sm;
    }
}


@tailwind components;
@tailwind utilities;


@layer utilities {
    
/* }

@layer utilities { */
    .standard-table {
        @apply rounded-md overflow-clip table w-full;
    }

    .compact-table > tbody > tr > td {
        @apply text-sm;
    }

    .standard-table > tbody > tr > td:first-child {
        width: 1%; /* Set minimum width for first column */
        white-space: nowrap; /* Prevent wrapping */
    }
    /* .standard-table > tbody > tr > td:not(:first-child) {
        width: 99%;
    } */
    .standard-table > thead > tr > th:not(:last-child),
    .standard-table > tbody > tr > td:not(:last-child) {
        @apply border-r-2 border-light-green-100;
    }
    /* Border on all rows except last row */
    .standard-table > thead > tr:last-child > th {
        @apply border-b-2 border-light-green-100;
    }

    .standard-table > tbody > tr:not(:last-child) > td {
        @apply border-b-2 border-light-green-100;
    }
    /* Remove bottom border from the last row */
    .standard-table > tbody > tr:last-child > td {
        @apply border-b-0;
    }
    /* Remove right border from the last column */
    .standard-table > tbody > tr > td:last-child {
        @apply border-r-0;
    }
}